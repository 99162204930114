.clients-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
    margin-bottom: 2rem;

    height: 100%;
}

.clients-content .clientCard {
    margin: 2rem 0;
}

.clients-content-normal {
    width: 100%;
}
.clients-content-reversed {
    width: 100%;
}
.clients-content-reversed .clientCard {
    flex-direction: row-reverse;
}
.clients-content-reversed .clientCard-image {
    margin-right: auto;
}
.clients-content-normal .clientCard-image {
    margin-left: auto;
}

@media screen and (max-width: 1000px) {
    .clients-content-reversed .clientCard {
        flex-direction: column;
    }
    .clients-content-reversed .clientCard-image {
        align-content: center;
        margin-right: 1rem;
    }
    .clients-content-normal .clientCard-image {
        margin-left: 1rem;
        align-content: center;
    }
}