.portfolio-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 2rem;
    margin-bottom: 2rem;

    height: 100%;
}

.portfolio-content .card {
    margin: 2rem 0;
}

.portfolio-content-normal {
    width: 100%;
}
.portfolio-content-reversed {
    width: 100%;
}
.portfolio-content-reversed .card {
    flex-direction: row-reverse;
}
.portfolio-content-reversed .card-image {
    margin-right: auto;
}
.portfolio-content-normal .card-image {
    margin-left: auto;
}

@media screen and (max-width: 1000px) {
    .portfolio-content-reversed .card {
        flex-direction: column;
    }
    .portfolio-content-reversed .card-image {
        align-content: center;
        margin-right: 1rem;
    }
    .portfolio-content-normal .card-image {
        margin-left: 1rem;
        align-content: center;
    }
}