.clientFeatures-container_feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.clientFeatures-container_feature-title {
    flex:1;
    max-width: 180px;
    margin-right: 2rem;
}

.clientFeatures-container_feature-role {
    margin-top: 0.5rem;
}
.clientFeatures-container_feature-role h1 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: -0.04rem;
}

.clientFeatures-container_feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;

    letter-spacing: -0.04rem;
}

.clientFeatures-container_feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    margin-bottom: 0.25rem;
}

.clientFeatures-container_feature-text {
    flex:2;
    max-width: 800px;
    display: flex;
    flex-direction: column;
}

.clientFeatures-container_feature-text p {
    margin: 0.5rem 0;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}
@media screen and (max-width: 550px) {
    .clientFeatures-container_feature.title h1 {
        font-size: 14px;
        line-height: 22px;
    }
    .clientFeatures-container_feature.title p {
        font-size: 12px;
        line-height: 20px;
    }
    .clientFeatures-container_feature {
        margin: 1rem 0;
    }
}