.contact {
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.contact-content {
    flex: 1;
    display: flex;
}