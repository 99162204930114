.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 2rem;
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.navbar-links_logo {
    margin-right: 2rem;
}
.navbar-links_logo img {
    width: 50px;
    height: 50px;
}

.navbar-links_container {
    display: flex;
    flex-direction: row;
}
.navbar-links_link{
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    left: 1153px;
    top: 55px;

    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    margin: 0 1rem;
    text-decoration: none;
}
.navbar-links_link div {
    width: 100%;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    visibility: hidden;
}
.navbar-links_link:hover div {
    visibility: visible;
}
.navbar-links_link:hover {
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.navbar-contact {
    margin-left:auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.navbar-links_container p,
.navbar-sign p,
.navbar-menu_container p {
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    height: 100%;
    cursor: pointer;
}
.navbar-links_link a {
    height: 100%;
    text-decoration: none;
}

.navbar-contact-button {
    padding: 0.5rem 1rem;
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    left: 1153px;
    top: 55px;

    background: var(--color-orange);
    border-radius: 2rem;

    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.navbar-menu {
    justify-content: flex-end;
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-manu svg {
    cursor: pointer;
}

.navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    z-index: 1;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 250px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.navbar-menu_container p {
    margin: 1rem 0;
}

.navbar-menu_container-links-contact {
    display: none;
}
.navbar-menu_container-links button {
    margin-top: 1rem;
}

@media screen and (max-width: 1050px ) {
    .navbar-links_container {
        display: none;
    }

    .navbar-menu {
        display:flex;
    }
}

@media screen and (max-width: 700px ) {

}

@media screen and (max-width: 550px ) {
    .navbar-contact {
        display: none;
    }

    .navbar-menu_container {
        top: 20px;
    }

    .navbar-menu_container-links-contact {
        display: block;
    }

    .navbar-menu {
        margin-left:auto;
    }
}