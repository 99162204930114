.clientCard {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    padding: 2rem;
    background: rgba(4, 44, 84, 1);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.clientCard-content {
    flex: 2;
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.clientCard-content .clientFeatures-container_feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

.clientCard-content .clientFeatures-container_feature-text {
    margin-top: 0.5rem;
}
.clientCard-image {
    flex: 1;
    display: flex;
    margin: 1rem;
    height: 100%;
    max-height: 300px;
}
.clientCard-image img{
    object-fit: contain;
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 500px;
    max-height: 300px;
}

@media screen and (max-width: 1000px) {
    .clientCard {
        flex-direction: column;
    }
}