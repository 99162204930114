.header {
    display: flex;
}

.header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04rem;
}
.header-content p {
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: normal;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
}
.header-content  button {
    margin-top: 1.5rem;
    padding: 0.5rem 1rem;
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    left: 1153px;
    top: 55px;

    background: var(--color-orange);
    border-radius: 2rem;

    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-image img{
    object-fit: cover;
    width: 80%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .header {
        flex-direction: column;
    }
    .header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .header h1 {
        font-size: 47px;
        line-height: 60px;
    }
    .header p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .header h1 {
        font-size: 36px;
        line-height: 48px;
    }
    .header p {
        font-size: 14px;
        line-height: 24px;
    }
}