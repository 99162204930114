.email {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    padding: 2rem;
    background: rgba(4, 44, 84, 1);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.emailform {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.emailform-header {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: row;
}

.emailform-header input {
    display: flex;
    flex: 1;
    width: 100%;
    min-height: 50px;
    font-size: 20px;
    line-height: 27px;
    border-radius: 5px;
    padding: 0 1rem;
    outline: none;
    color: white;
    font-family: var(--font-family);
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
}

.emailform-header-email {
    width: 100%;
    margin-left: auto;
}

.emailform-header-name {
    width: 100%;
    margin-right: 2rem;
}

.emailform-message {
    flex:1;
    margin: 1rem 0;
}
.emailform-message textarea  {
    width: 100%;
    height: 100%;
    font-size: 20px;
    line-height: 27px;
    border-radius: 5px;
    padding: 1rem 1rem;
    outline: none;
    color: white;
    font-family: var(--font-family);
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
}

.emailform-send {
    display: flex;
    flex-direction: row-reverse;
    margin: 1rem 0 2rem;
    justify-self: flex-end;
}

.emailform-send input {
    padding: 0.5rem 1rem;
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    left: 1153px;
    top: 55px;

    background: var(--color-orange);
    border-radius: 2rem;

    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}